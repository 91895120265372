export default{
    
    //Devices
    DEVICES:"DEVICES",
    SET_DEFUALT_DEVICE:'SET_DEFUALT_DEVICE',
    SAVE_CONFIG:'SAVE_CONFIG',
    SAVE_SPEAKER:'SAVE_SPEAKER',
    NO_STREAM:'NO_STREAM',
    
    //AES 256 Encryption
    SERVER_PUBLIC_KEY:'SERVER_PUBLIC_KEY',
    
    //Check user
    CHECK_USER:'CHECK_USER',
    CLEAR_USER:'CLEAR_USER',
    GET_COUNTRIES:'GET_COUNTRIES',
    TRANSLATION_FETCH: 'TRANSLATION_FETCH',
    CLEAR_ALL:'CLEAR_ALL'
}