
import { toast } from 'react-toastify';


export default function checkNetwork() {
    
    window.addEventListener('online', () =>{
        toast.dismiss();
        toast.success('Network Connected', {
            position: "bottom-left",
            autoClose: 2000,
            className: 'toast-success-container',
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        });
        
    window.addEventListener('offline', () =>{
        toast.error('Network not Connected', {
            position: "bottom-left",
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    } );

}
