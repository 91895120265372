import { createStore, applyMiddleware } from 'redux';
import promiseMiddleware from 'redux-promise-middleware';
import thunk from 'redux-thunk';
//import logger from 'redux-logger';
import { routerMiddleware } from 'react-router-redux';
import { createBrowserHistory } from 'history';

import reducers from '../reducer';

export const historys = createBrowserHistory();

const Store = createStore(reducers, applyMiddleware(routerMiddleware(historys),promiseMiddleware, thunk));

export default Store;