import { toast } from 'react-toastify';

function success(title, duration) {
    toast.success(title, {
        position: "bottom-left",
        className: 'toast-success-container',
        autoClose: duration ? duration : 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

function warning(title) {
    toast.warning(title, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}


function error(title) {
    toast.error(title, {
        position: "bottom-left",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
}

const dismissAll = () =>  toast.dismiss();


export default {
    success,
    warning,
    error,
    dismissAll
}
