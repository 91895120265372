import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import store from './store';
import Router from './router';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { ToastContainer } from 'react-toastify';
import CheckNetwork from './utils/check-network';
import 'react-toastify/dist/ReactToastify.min.css';

function App() {
  
  useEffect(() => {
    CheckNetwork()
  }, []);

  const theme = createMuiTheme({
    palette: {
      primary: {
        main: '#339379',
      }
    },
    typography: {
      button: {
        textTransform: "none"
      }
    }
  });

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router />
        <ToastContainer />
      </ThemeProvider>
    </Provider>
  );
}

export default App;