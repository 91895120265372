import React, { Suspense, lazy } from 'react';
import { Route, Switch, Router } from 'react-router-dom';
import { historys } from '../store';
import locations from './locations';
import Loader from '../components/loader';

const Home = lazy(()=> import('../pages/home'));
const Room = lazy(()=> import('../pages/room/chatroom'));
const Error = lazy(()=> import('../pages/error'));

export default () => {
    return (
      <Router history={historys}>
        <Suspense fallback={<Loader />}>
          <Switch>    
            <Route exact path={locations.home} component={Home} />
            <Route exact path={locations.room} component={Room} />
            <Route exact path={locations.main} component={Home} />
            <Route exact path={locations.roomFull} component={()=><Error
                                                error="Error"
                                                desc="Room is Full" 
                                                buttonText="Back to Home" 
                                                buttonLink={locations.home} />} />
            <Route exact path={locations.endCall} component={()=><Error
                                                error="Call Ended"
                                                desc="Invalid Room Id" 
                                                buttonText="Rejoin the Call" 
                                                buttonLink={locations.home} />} />
            <Route path="/*" component={()=><Error
                                                error="404"
                                                desc="Page not Found" 
                                                buttonText="Back to Home" 
                                                buttonLink={locations.home} />} />
          </Switch>
        </Suspense>
      </Router>
    );
}