import events from '../action/events';

const initialState = {
  devices: {
    video: '',
    audio: ''
  },
  loggedIn: true,
  videoDevice: null,
  resolution: '',
  microphoneDevice: null,
  speakerDevice: null,
  constraints: {
    audio: true,
    video: { width: 1280, height: 720 },
  },
  config: {
    audio: true,
    video: true,
    resolution: '720'
  },
  defaultDevices:{
    audio:null,
    video:null
  },
  noStream:false
  
}

let defintions = { '360' : { width: 480,height: 360,}, '720': { width: 1280, height: 720,}}


export default (state = initialState, action) => {
  switch (action.type) {


    case events.DEVICES:
      let data = action.payload;
      let constraints = {};
      let videoDevice = state.videoDevice;
      let microphoneDevice = state.microphoneDevice;
      if (data.type === 'audio') {
        constraints = { audio: { deviceId: { exact: action.payload.deviceId } } };
        microphoneDevice = action.payload.deviceId;
      }
      if (data.type === 'video') {
        constraints = {  video: { ...state.constraints.video, deviceId: { exact: action.payload.deviceId } } };
        videoDevice = action.payload.deviceId
      }
      let videoConfig = state.config;
      if (data.type === 'resolution') {
        constraints = {  video: { ...state.constraints.video, ...defintions[action.payload.res] } };
        videoConfig = { ...state.config, resolution:action.payload.res }
      }
      return {
        ...state,
        constraints: {
          ...state.constraints,
          ...constraints,
        },
        config:videoConfig,
        videoDevice:videoDevice,
        microphoneDevice:microphoneDevice
      };

    case events.SET_DEFUALT_DEVICE:
      let _video = state.constraints.video
      if(!action.payload.video){
        _video = false
      }
      
      let _audio = state.constraints.audio
      if(!action.payload.audio){
        _audio = false
      }
      
      return {
        ...state,
        defaultDevices: action.payload,
        constraints:{
          ...state.constraints,
          video:_video,
          audio:_audio
        }
      }
    case events.SAVE_SPEAKER:
      return {
        ...state,
        speakerDevice: action.payload
      }
    case events.SAVE_CONFIG:
      return {
        ...state,
        loggedIn: false,
        config: { ...state.config, ...action.payload }
      };
      
      case events.NO_STREAM: 
         return {
           ...state,
           noStream:action.payload 
         }
         
    
         case events.CLEAR_ALL:
          return {
            ...state,
            devices: {
              video: '',
              audio: ''
            },
            loggedIn: true,
            videoDevice: null,
            resolution: '',
            microphoneDevice: null,
            speakerDevice: null,
            constraints: {
              audio: true,
              video: { width: 1280, height: 720 },
            },
            config: {
              audio: true,
              video: true,
              resolution: '720'
            },
            defaultDevices:{
              audio:null,
              video:null
            },
            noStream:false
            
          }
      
    default:
      return state
  }
};
