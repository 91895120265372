import events from '../action/events';
import { updateTransalation, updateLanguage } from '../i18'

const initialState = {
    countries:[],
    transFetch:'',
    language:'en',
    sLanguage:{ language_code:'en', language:'English', flag_url:'images/locales/en/icon.png' }
}

export default (state = initialState, action) => {
  switch (action.type) {
    
    case 'LANGUAGE_CHANGE':
      return {
        ...state,
        sLanguage:action.payload
      }
    
    
    
    
    

  case events.GET_COUNTRIES + '_FULFILLED':
    return { 
        ...state,
        countries:action.payload.data
     };

     case `${events.TRANSLATION_FETCH}_PENDING`: {
      return {
          ...state,
          language:action.meta.language,
          transFetch:'PENDING'
      };
  }
     
  case `${events.TRANSLATION_FETCH}_FULFILLED`: {
      updateTransalation(action.meta.language, action.payload.data);
      return {
          ...state,
          transFetch:'FULFILLED',
          language:action.meta.language
      };
  }

  default:
    return state
  }
};
