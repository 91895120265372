import events from '../action/events';

const initialState = {
    serverPub64:''
}

export default (state = initialState, action) => {
  switch (action.type) {

  case events.SERVER_PUBLIC_KEY:
    return {
         ...state,
        serverPub64:action.payload 
        };

  default:
    return state
  }
};
