import events from '../action/events';
import Toast from '../utils/tostify';

const initialState = {
  checkUserStatus: '',
  checkUser: null
}

export default (state = initialState, action) => {
  switch (action.type) {

    case events.CHECK_USER + '_PENDING':
      return {
        ...state,
        checkUserStatus: 'PENDING',
        checkUser: null
      };

    case events.CHECK_USER + '_REJECTED':
      let data;
      try {
        data = action.payload.response.data
        if (!action.meta && action.payload.response.status === 401 && data && data.error) {
          Toast.error(data.error)
        }
      } catch (error) {
        data = null
      }
      return {
        ...state,
        checkUserStatus: 'REJECTED',
        checkUser: data
      };

    case events.CHECK_USER + '_FULFILLED':
      return {
        ...state,
        checkUserStatus: 'FULFILLED',
        checkUser: action.payload.data
      };

    case events.CLEAR_USER:
      return {
        ...state,
        checkUserStatus: '',
        checkUser: null
      };

    case events.CLEAR_ALL:
      return {
        ...state,
        checkUserStatus: '',
        checkUser: null
      }
    default:
      return state
  }
};
