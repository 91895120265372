import { routerReducer } from 'react-router-redux';
import { combineReducers } from 'redux';
import devices from './devices';
import aes from './aes-256-encryption';
import user from './checkUser';
import Transalation from './transalation';

export default combineReducers({
    router: routerReducer,
    devices,
    aes,
    user,
    Transalation
});